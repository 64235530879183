import * as Models from "static/js/app/models/__index";
import { codeweavers_finance_calculator } from "themes/module_finance_plugin/static/js/codeweavers_finance_plugin";
import {ivendi_finance_calculator} from "themes/module_finance_plugin/static/js/ivendi_finance_plugin";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import { apiBaseUrl } from "static/js/app/api/snSvrApi";
import * as Api from "static/js/app/api/endpoints";
export class finance_plugin
{
    public static init( financeConfig: FinanceConfig, financeTerms: Models.FinanceSearchTerms, vehicleSingle: Models.Vehicle)
    {       
        if (financeTerms != null && vehicleSingle !=null)
        {
            switch(financeConfig.provider) {
                case "codeweavers":
                    
                    codeweavers_finance_calculator.init(financeTerms,vehicleSingle,financeConfig.finance_cta);
                   
                    break;
                case "ivendi":
                    if (vehicleSingle.sold == false)
                    {
                        finance_plugin.fetchMonthlyPrice(vehicleSingle.id, financeTerms);
                    }
                  
                    ivendi_finance_calculator.init(financeConfig.api_key_username,financeTerms,vehicleSingle);
                    if (financeConfig.reserve_button_type =="ivendi")
                    {
                        ivendi_finance_calculator.initReserveButton(financeConfig.api_key_username,financeTerms,vehicleSingle);
                    }
                    if (financeConfig.finance_checker == "both" || financeConfig.finance_checker == "vehicle details" )
                    {
                        ivendi_finance_calculator.initFinanceChecker(financeConfig.api_key_username,financeTerms,vehicleSingle);
                    }
                    break;
                case "evolution":
                    if (vehicleSingle.sold == false)
                    {
                        finance_plugin.fetchMonthlyPrice(vehicleSingle.id, financeTerms);
                    }
                    break;
                default: 
                    break;
            }
        }
    }

    public static fetchMonthlyPrice( vehicleId: string, financeTerms:Models.FinanceSearchTerms)
    {
        const monthlyPayementLink = document.getElementById("monthlyPaymentLink");
        if (monthlyPayementLink !=null) {
              
            
            Api.Vehicles.getMontlhyPrice(vehicleId, financeTerms).then(function(monlthyPrice: number)
            {
                finance_plugin.setMonthlyPrice(monlthyPrice);
                    
            }).catch(function() {

                finance_plugin.hideMonthlyPrice();
            }
       );
        }
    } 
    
    public static setMonthlyPrice(monthlyPrice: number) 
    {
        const monthlyPaymentLink = document.getElementById("monthlyPaymentLink");
        if (monthlyPaymentLink !=null && monthlyPrice != null && monthlyPrice != NaN)
        {
            const monthlyPriceElement = monthlyPaymentLink.querySelector<HTMLElement>("#monthlyPaymentAmount");
                    monthlyPriceElement.innerText = `£${Number(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits:2 })}`;                    
                    monthlyPaymentLink.classList.remove("invisible");
        }
    }
    public static hideMonthlyPrice()
    {
        const monthlyPaymentLink = document.getElementById("monthlyPaymentLink");
        if (monthlyPaymentLink !=null)
        {                    
            monthlyPaymentLink.classList.add("invisible");
        }
    }

    
}
