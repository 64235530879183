import * as Models from "static/js/app/models/__index";
import * as Dtos from "static/js/app/api/dtos/__index";

export function VehicleDtoToVehicle(dto: Dtos.VehicleDto | null): Models.Vehicle | null {
    if(dto == null) {
        return null;
    }

    return new Models.Vehicle(
        dto.type,
        dto.vehicle_type,
        dto.vehicle_type_display,
        dto.fuel_type,
        dto.fuel_type_display,
        dto.manufacturer,
        dto.manufacturer_display,
        dto.model,
        dto.model_display,
        dto.derivative,
        dto.derivative_display,
        dto.body_type,
        dto.body_name,
        dto.gearbox,
        dto.transmission,
        dto.transmission_display,
        dto.description,
        dto.engine_capacity,
        dto.mpg,
        dto.year_built,
        dto.mileage,
        dto.price,
        dto.list_price,
        dto.discount_price,
        dto.engine,
        dto.co2,
        dto.tax_rate_12,
        dto.monthly_payment,
        dto.branch_name,
        dto.insurance_group,
        dto.id,
        (dto.images || []).map(VehicleImageUrlsDtoToVehicleImageUrls),
        dto.imageCount,
        dto.colour,
        dto.basic_colour,
        dto.basic_colour_display,
        dto.urban_mpg,
        dto.extra_urban_mpg,
        dto.height,
        dto.width,
        dto.length,
        dto.acceleration,
        dto.bhp,
        dto.max_speed,
        dto.features,
        dto.vehicle_sash,
        dto.notes,
        dto.datasheetPath,
        dto.sold,
        dto.branch_id,
        dto.vat_exempt,
        dto.vat_excluded,
        dto.vat_qualifying,
        dto.unladened_weight,
        dto.mtplm,
        dto.internal_length,
        dto.awning_size,
        dto.seats,
        dto.doors,
        dto.mot_expiry,
        dto.body_length,
        dto.internal_width,
        dto.exhaust_manufacturer,
        dto.muffler,
        dto.wheelbase,
        dto.boot_space_seats_down,
        dto.boot_space_seats_up,
        dto.kerb_weight,
        dto.max_loading_weight,
        dto.berth,
        dto.category,
        dto.layout,
        dto.youtube_link,
        dto.manufacturers_warranty,
        dto.manufacturers_warranty_expiry,
        dto.equipment_drivers_convenience,
        dto.equipment_safety,
        dto.equipment_exterior,
        dto.equipment_interior,
        dto.equipment_technical,
        dto.equipment_security,
        dto.equipment_additional,
        dto.source_advert_id,
        dto.cap_id,
        dto.ivendi_quotee_uid,
        dto.vrm,
        dto.registration_date,
        (dto.finance_quotes || []).map(FinanceQuoteDtoToFinanceQuote),
        (dto.file_links || []).map(VehicleFileDtoToVehicleFile),
        dto.equipment_entertainment,
        dto.equipment_wheels,
        dto.equipment_packs,
        dto.equipment_trim,
        dto.websection,
        dto.websection_display,
        dto.reg_letter,
        dto.reserved,
        dto.new_vehicle,
        dto.used_or_new,
        dto.interior_colour
    )
}

export function VehicleSearchResultDtoToVehicleSearchResult(dto: Dtos.VehicleSearchResultDto | null): Models.VehicleSearchResult | null {
    if(dto == null) {
        return null;
    }

    return new Models.VehicleSearchResult(
        dto.pageNumber,
        dto.pageSize,
        dto.total,
        (dto.results || []).map(VehicleDtoToVehicle),
        dto.totalPages,
        dto.advertUrls
    )
}

export function VehicleCountDtoToVehicleCount(dto: Dtos.VehicleCountDto | null): Models.VehicleCount | null {
    if(dto == null) {
        return null;
    }

    return new Models.VehicleCount(
        dto.count,
        dto.typeName,
        dto.typeValue,
        dto.makeName,
        dto.makeValue,
        dto.modelName,
        dto.modelValue
    );
}

export function SearchTermSpecDtoToSearchTermSpec(dto: Dtos.SearchTermSpecDto | null): Models.SearchTermSpec | null {
    if(dto == null) {
        return null;
    }

    return new Models.SearchTermSpec(
        dto.fieldName,
        dto.fieldType,
        dto.rangeSearch,
        dto.sortable,
        dto.minRange,
        dto.maxRange,
        (dto.acceptedSearchTerms || []).map(AcceptedSearchTermSpecDtoToAcceptedSearchTermSpec)
    );
}

export function AcceptedSearchTermSpecDtoToAcceptedSearchTermSpec(dto: Dtos.AcceptedSearchTermSpecDto | null): Models.AcceptedSearchTermSpec | null {
    if(dto == null) {
        return null;
    }

    return new Models.AcceptedSearchTermSpec(
        dto.name,
        dto.value
    );
}

export function VehicleImageUrlsDtoToVehicleImageUrls(dto: Dtos.VehicleImageUrlsDto | null): Models.VehicleImageUrls | null {
    if(dto == null) {
        return null;
    }

    return new Models.VehicleImageUrls(
        dto.full,
        dto.i800x600,
        dto.i320x240,
        dto.i160x120,
        dto.i850x638
    )
}

export function VrmResultDtoToVrmResult(dto: Dtos.VRMResultDto | null): Models.VRMResult | null{
    if(dto == null) {
        return null;
    }
    return new Models.VRMResult(
        dto.make,
        dto.model,
        dto.derivative,
        dto.body,
        dto.fuel,
        dto.gearbox,
        dto.colour,
        dto.mileage,
        dto.firstRegisteredDate,
        dto.motExpiry,
        dto.numberOfOwners,
        dto.isSuccessful,
        dto.vrm,
        dto.year,
        dto.engineCapacityCC
    );
}

export function PartExchageImageFileDtoToPartExchageImageFile(dto: Dtos.PartExchangeImageFileDto | null): Models.PartExchangeImageFile | null{
    if(dto == null) {
        return null;
    }
    return new Models.PartExchangeImageFile(
        dto.filename,
        dto.fileType,
        dto.uploadUri,
        dto.imagePath,
        dto.thumbnailPath,
        dto.isError,
        dto.errorMessage
    );
}

export function PartExchageImageFileDtoArrayToPartExchageImageFileArray(dto: Dtos.PartExchangeImageFileDto[] | null): Models.PartExchangeImageFile[] | null{
    if(dto == null) {
        return null;
    }
    return dto.map(PartExchageImageFileDtoToPartExchageImageFile);
}

export function VehicleFileDtoToVehicleFile(dto: Dtos.VehicleFileDto | null ): Models.VehicleFile | null {
    if (dto == null)
    {
        return null;
    }
    return new Models.VehicleFile(
        dto.title,
        dto.url
    );
}

export function FinanceQuoteDtoToFinanceQuote(dto: Dtos.FinanceQuoteDto | null ): Models.FinanceQuote {
    if (dto == null) {
        return null;
    }
    return new Models.FinanceQuote(
            dto.term,
            dto.annual_mileage,
            dto.percentage_deposit,
            dto.monthly_payment,
            dto.vat_inclusive_payment,
            dto.vat_exclusive_payment,
            dto.is_vat_exclusive,
            dto.name,
            dto.dealer_terms_id,
            dto.quote_id,
            dto.total_deposit,
            dto.amount_of_credit,
            dto.total_amount_payable,
            dto.fixed_rate_of_interest,
            dto.apr,
            dto.otr_price,
            dto.total_amount_of_charges,
            dto.final_payment,
            dto.type,
            dto.excess_mileage_rate,
            dto.contract_length,
            dto.lender);
}
