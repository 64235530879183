import * as Models from "static/js/app/models/__index";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";

export default class finance_checker
{
    public static GetFinanceCheckerUrl( finance_config: FinanceConfig, vehicleSingle: Models.Vehicle) :string
    {       
        if (finance_config != null && finance_config.provider != 'none' && vehicleSingle !=null && vehicleSingle.vehicle_type !== "caravan" && vehicleSingle.finance_quotes != null && vehicleSingle.finance_quotes.length > 0 )
        {
            let quoteId = vehicleSingle.finance_quotes[0].quote_id;
        
           
            switch(finance_config.provider) {
                case "codeweavers":
                    return "";
                case "ivendi":
                    return `https://my.newvehicle.com/start?quoteId=${quoteId}&imageUrl=${finance_checker.GetMainImageUrl(vehicleSingle)}&returnUrl=${window.location.href}`                                              
                default: 
                    return "";
            }
        }
        return "";
    }

    private static GetMainImageUrl( vehicle: Models.Vehicle)
    {
        return `https://${window.location.host}${vehicle?.main_image?.i850x638 ?? "/awaitingimage.jpg"}`;       
    }
}